import { useEffect } from 'react';

export const useScrollEffect = (
  callback: React.EffectCallback,
  args?: React.DependencyList,
): void =>
  useEffect(() => {
    document.addEventListener('scroll', callback);

    return () => {
      document.removeEventListener('scroll', callback);
    };
  }, args);
