import { useState, RefObject, useEffect } from 'react';
import { useIntersection } from './use-intersection';
import { useScrollEffect } from './use-scroll-effect';

export interface UseBoxScrollProps {
  outerRef: RefObject<HTMLDivElement>;
  innerRef: RefObject<HTMLDivElement>;
}

export const useBoxScroll = ({ outerRef, innerRef }: UseBoxScrollProps): number => {
  const [offsetTop, setOffsetTop] = useState(0);
  const [height, setHeight] = useState({
    hasHeight: false,
    inner: 0,
    outer: 0,
  });
  const [position, setPosition] = useState(0);

  const entry = useIntersection({
    node: outerRef,
    // TODO: Move to prop
    rootMargin: '50px 50px 50px 50px',
  });

  useScrollEffect(() => {
    if (innerRef?.current && entry?.isIntersecting) {
      setOffsetTop(innerRef.current.offsetTop);
    }
  }, [entry]);

  useEffect(() => {
    if (innerRef?.current && outerRef?.current) {
      setHeight({
        hasHeight: true,
        inner: innerRef.current.clientHeight,
        outer: outerRef.current.clientHeight,
      });
    } else {
      setHeight({
        hasHeight: false,
        inner: 0,
        outer: 0,
      });
    }
  }, [innerRef, outerRef]);

  useEffect(() => {
    const rawPosition = height.hasHeight ? offsetTop / (height.outer - height.inner) : 0;
    const roundedPosition = Math.round(rawPosition * 1000) / 1000;

    setPosition(roundedPosition);
  }, [height, offsetTop]);

  return position;
};
