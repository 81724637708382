import React, { useRef, RefObject, useContext, useMemo } from 'react';
import { Logger } from '@feature-hub/core';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { Dependencies } from '../FeatureAppTypes';

interface State {
  readonly logger?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly inViewRef: RefObject<HTMLDivElement>;
  readonly asyncSsrManager?: AsyncSsrManagerV1;
  readonly serializedStateManager?: SerializedStateManagerV1;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly localeService: GfaLocaleServiceV1;
  readonly contentService: ContentServiceV1;
  readonly renderModeService: RenderModeServiceV1;
}

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly children: JSX.Element;
}

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({ featureServices, children }: ContextValue): JSX.Element => {
  const {
    'dbad:audi-vue-formatter-service': vueFormatterService,
    'gfa:locale-service': localeService,
  } = featureServices;
  const logger = featureServices['s2:logger'];
  const trackingService = featureServices['audi-tracking-service'];
  const inViewRef = useRef<HTMLDivElement>(null);
  const asyncSsrManager = featureServices['s2:async-ssr-manager'];
  const serializedStateManager = featureServices['s2:serialized-state-manager'];
  const contentService = featureServices['audi-content-service'];
  const renderModeService = featureServices['audi-render-mode-service'];
  const state: State = {
    logger,
    trackingService,
    inViewRef,
    asyncSsrManager,
    serializedStateManager,
    vueFormatterService,
    localeService,
    contentService,
    renderModeService,
  };

  return (
    <Context.Provider value={useMemo(() => state, [state])}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};

export const useLogger = (): Logger | undefined => useContext(Context).logger;
