import {
  LayoutItem,
  Layout,
  Text,
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
} from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';
import { addWidthParameter } from '@oneaudi/feature-app-utils';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImageFadeOverOnScrollProps, WltpProps } from '../FeatureAppTypes';
import { ScrollBox } from './scroll-box';

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  .adobe-ue-edit & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

const StyledImgAbsolute = styled(StyledImg)`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`;

StyledImgAbsolute.displayName = 'StyledImgAbsolute';

const LegalArea = styled.div`
  background-color: var(
    ${(props) =>
      props.theme.name === 'Audi Dark Theme'
        ? props.theme.colors.base.brand.black
        : props.theme.colors.base.brand.white}
  );
`;

LegalArea.displayName = 'LegalArea';

const StyledBlockSpan = styled.span`
  display: block;
`;

const WltpSpan = styled.span`
  color: var(${(props) => props.theme.colors.ui.secondary});
  > sup {
    line-height: 0%;
  }
`;

WltpSpan.displayName = 'WltpSpan';

const WltpValuesComponent: React.FC<WltpProps> = ({
  formattedConsumption,
  formattedEmission,
  formattedCo2Class,
  formattedDischargedCo2Class,
  formattedDischargedConsumption,
}: WltpProps) => {
  return (
    <StyledBlockSpan>
      <WltpSpan data-testid="consumption">{formattedConsumption}</WltpSpan>
      <WltpSpan data-testid="emission">; {formattedEmission}</WltpSpan>
      {formattedCo2Class && <WltpSpan data-testid="co2Class">; {formattedCo2Class}</WltpSpan>}
      {formattedDischargedConsumption && (
        <WltpSpan data-testid="dischargedConsumption">; {formattedDischargedConsumption}</WltpSpan>
      )}
      {formattedDischargedCo2Class && (
        <WltpSpan data-testid="dischargedCo2Class">; {formattedDischargedCo2Class}</WltpSpan>
      )}
    </StyledBlockSpan>
  );
};

const addSrcSet = (url: string, width: number) => `${addWidthParameter(url, width)} ${width}w`;
const addSrcSets = (url: string, widths: number[]) =>
  widths.map((width) => addSrcSet(url, width)).join(',');
const portraitMinRendition = 375;
const landscapeRenditions = [768, 834, 1280, 1536, 1920];
const portraitRenditions = [portraitMinRendition, 768, 1440];
export const ImageFadeOverOnScroll: React.FunctionComponent<ImageFadeOverOnScrollProps> = ({
  legalData,
  altText,
  images,
  impressionTracking,
  endOfModuleTracking,
}) => {
  const [position, setPosition] = useState(0);
  const [disableAnimation, setDisableAnimation] = useState(false);
  if (impressionTracking) impressionTracking(disableAnimation);

  useEffect(() => {
    if (endOfModuleTracking && !disableAnimation && position === 1) {
      endOfModuleTracking();
    }
  });

  function addMediaQueryEventListener(
    mediaQuery: MediaQueryList,
    listener: (e: MediaQueryListEvent) => void,
  ) {
    if (typeof mediaQuery.addEventListener === 'function') {
      mediaQuery.addEventListener('change', listener, { passive: true });
    } else {
      // Fallback for older Browser e.g. Safari < v5
      mediaQuery.addListener(listener);
    }
  }

  function removeMediaQueryEventListener(
    mediaQuery: MediaQueryList,
    listener: (e: MediaQueryListEvent) => void,
  ) {
    if (typeof mediaQuery.removeEventListener === 'function') {
      mediaQuery.removeEventListener('change', listener);
    } else {
      // Fallback for older Browser e.g. Safari < v5
      mediaQuery.removeListener(listener);
    }
  }

  function updateDisableAnimation(mediaQuery: MediaQueryList | MediaQueryListEvent) {
    setDisableAnimation(mediaQuery.matches);
  }

  // reduce motion
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    updateDisableAnimation(mediaQuery);

    addMediaQueryEventListener(mediaQuery, updateDisableAnimation);

    return () => {
      removeMediaQueryEventListener(mediaQuery, updateDisableAnimation);
    };
  }, []);
  return (
    <>
      <ScrollBox outerHeight="150vh" innerHeight="100vh" onScroll={setPosition}>
        <span role="img" aria-label={altText}>
          <picture data-testid="image-0">
            <UeElement
              type="media"
              property="images_firstImage_landscape"
              label="First image portrait"
            >
              {(ueProps) => (
                <source
                  media="(min-width:768px)"
                  srcSet={addSrcSets(images.firstImage.landscape, landscapeRenditions)}
                  sizes="(min-width:1920px) 1920px, 100vw"
                  width="1920"
                  height="1080"
                  {...ueProps}
                />
              )}
            </UeElement>
            <UeElement
              type="media"
              property="images_firstImage_portrait"
              label="Second image portrait"
            >
              {(ueProps) => (
                <StyledImg
                  src={addWidthParameter(images.firstImage.portrait, portraitMinRendition)}
                  srcSet={addSrcSets(images.firstImage.portrait, portraitRenditions)}
                  sizes="100vw"
                  alt={altText}
                  width="1440"
                  height="1920"
                  loading="lazy"
                  {...ueProps}
                />
              )}
            </UeElement>
          </picture>
          {!disableAnimation && (
            <picture data-testid="image-1">
              <UeElement
                type="media"
                property="images_secondImage_landscape"
                label="Second image landscape"
              >
                {(ueProps) => (
                  <source
                    media="(min-width:768px)"
                    srcSet={addSrcSets(images.secondImage.landscape, landscapeRenditions)}
                    sizes="(min-width:1920px) 1920px, 100vw"
                    width="1920"
                    height="1080"
                    {...ueProps}
                  />
                )}
              </UeElement>
              <UeElement
                type="media"
                property="images_secondImage_portrait"
                label="Second image portrait"
              >
                {(ueProps) => (
                  <StyledImgAbsolute
                    style={{ opacity: String(position) }}
                    src={addWidthParameter(images.secondImage.portrait, portraitMinRendition)}
                    srcSet={addSrcSets(images.secondImage.portrait, portraitRenditions)}
                    sizes="100vw"
                    alt={altText}
                    width="1440"
                    height="1920"
                    loading="lazy"
                    {...ueProps}
                  />
                )}
              </UeElement>
            </picture>
          )}
        </span>
      </ScrollBox>

      {(legalData.wltpData.length > 0 || legalData.additionalText) && (
        <ThemeProvider theme={legalData.theme === 'dark' ? audiDarkTheme : audiLightTheme}>
          <LegalArea data-testid="legal-text-area" className={legalData.theme}>
            <Layout justify="center">
              <LayoutItem
                basis={{ xs: '86%', m: '92%' }}
                shrink="0"
                spaceStackStart="l"
                spaceStackEnd="xxxl"
              >
                <Text variant="copy2">
                  {legalData.wltpData &&
                    legalData.wltpData.map((data, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <WltpValuesComponent {...data} key={index} />
                    ))}
                  {legalData.additionalText && (
                    <WltpSpan data-testid="additional-legal-text">
                      <UeElement
                        type="text"
                        property="legalData_additionalText"
                        label="Additional Text"
                      >
                        {legalData.additionalText}
                      </UeElement>
                    </WltpSpan>
                  )}
                </Text>
              </LayoutItem>
            </Layout>
          </LegalArea>
        </ThemeProvider>
      )}
    </>
  );
};
