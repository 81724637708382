import * as React from 'react';
import styled from 'styled-components';
import { ScrollBoxProps } from '../FeatureAppTypes';
import { useBoxScroll } from '../utils/use-box-scroll';

type Ref = React.RefObject<HTMLDivElement>;

const Outer = styled.div<{ ref: Ref; height?: string }>`
  box-sizing: border-box;
  height: ${({ height = '300vh' }) => height};
  position: relative;
  .adobe-ue-edit & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

const Inner = styled.div<{ ref: Ref; height?: string }>`
  box-sizing: inherit;
  height: ${(props) => props.height || '100vh'};
  overflow: hidden;
  position: sticky;
  top: 0;
  .adobe-ue-edit & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

export const ScrollBox: React.FC<ScrollBoxProps> = (args) => {
  const { children, outerHeight, innerHeight, onScroll } = args;
  const outerRef: Ref = React.useRef<HTMLDivElement>(null);
  const innerRef: Ref = React.useRef<HTMLDivElement>(null);
  const position = useBoxScroll({ outerRef, innerRef });

  React.useEffect(() => {
    if (onScroll) {
      onScroll(position);
    }
  }, [position]);

  return (
    <Outer ref={outerRef} height={outerHeight} data-testid="component">
      <Inner ref={innerRef} height={innerHeight}>
        {children}
      </Inner>
    </Outer>
  );
};
