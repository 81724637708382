import { Asset } from '@oneaudi/falcon-tools';
import { Content, HeadlessContent } from '../EditorContentTypes';

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      images: {
        firstImage: {
          landscape: {
            assetValue: (content.fields.images_firstImage_landscape as Asset).path,
          },
          portrait: {
            assetValue: (content.fields.images_firstImage_portrait as Asset).path,
          },
        },
        secondImage: {
          landscape: {
            assetValue: (content.fields.images_secondImage_landscape as Asset).path,
          },
          portrait: {
            assetValue: (content.fields.images_secondImage_portrait as Asset).path,
          },
        },
        altText: content.fields.images_altText,
      },
      legalData: {
        theme: content.fields.legalData_theme,
        additionalText: content.fields.legalData_additionalText ?? '',
        wltpKeys: content.fields.legalData_wltpKeys,
      },
    };
    return normalisedContent;
  }
  return content as Content;
};
