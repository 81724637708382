import React from 'react';
import { Context } from './index';

export interface TrackingManager {
  ready(version: string): void;
  impression(text: string): void;
  endOfModule(): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'image-fade-over-scroll',
    currentURL: `${host}${pathname}`,
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentAdditions: {
      componentInfo: {
        implementer: 2,
        version,
      },
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = React.useContext(Context);
  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) logger.debug('Sending tracking event: ', payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) logger.debug('Sending tracking event: ', payload);

        const componentPayload = createComponentPayload(version);
        if (logger) logger.debug('Sending component info', componentPayload);
        trackingService.track({ event: payload.event, componentUpdate: componentPayload });
      }
    }

    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },

      impression(text) {
        track('impression', `Image Fade Over Scroll - impression`, {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: text,
          pos: '',
        });
      },

      endOfModule() {
        track('impression', `Image Fade Over Scroll - end of module`, {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: 'standard',
          pos: '',
        });
      },
    };
  }, [trackingService]);
}
