import { ReactNode } from 'react';
import { ConsumptionsAndEmissions } from '@oneaudi/vue-formatter-service';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
  getImageUrls,
} from '@oneaudi/feature-app-utils';
import {
  ImagesProps as FAImagesProps,
  ImageFadeOverOnScrollProps,
  WltpProps,
} from '../FeatureAppTypes';
import { Content, ImagesProps } from '../EditorContentTypes';

const mapImages = (images: ImagesProps): FAImagesProps => {
  return {
    firstImage: {
      landscape: getImageUrls(images.firstImage.landscape.assetValue)[0],
      portrait: getImageUrls(images.firstImage.portrait.assetValue)[0],
    },
    secondImage: {
      landscape: getImageUrls(images.secondImage.landscape.assetValue)[0],
      portrait: getImageUrls(images.secondImage.portrait.assetValue)[0],
    },
  };
};

export const mapToTeaserProps = (
  content: Content,
  consumptionsAndEmissions: ConsumptionsAndEmissions[],
): ImageFadeOverOnScrollProps => {
  let additionalText: Iterable<ReactNode> | undefined;
  if (content.legalData.additionalText) {
    additionalText = renderTextWithFootnotesReferencesV2(content.legalData.additionalText);
  }

  const wltpData: WltpProps[] = [];
  consumptionsAndEmissions.forEach(
    ({
      formattedConsumption,
      formattedEmission,
      formattedCo2Class,
      formattedDischargedConsumption,
      formattedDischargedCo2Class,
    }) => {
      if (formattedConsumption && formattedEmission) {
        wltpData.push({
          formattedConsumption: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedConsumption),
          ),
          formattedEmission: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedEmission),
          ),
          formattedCo2Class: formattedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedCo2Class),
              )
            : undefined,
          formattedDischargedConsumption: formattedDischargedConsumption
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedConsumption),
              )
            : undefined,
          formattedDischargedCo2Class: formattedDischargedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedCo2Class),
              )
            : undefined,
        });
      }
    },
  );

  return {
    images: mapImages(content.images),
    altText: content.images.altText,
    legalData: {
      theme: content.legalData.theme,
      additionalText,
      wltpData,
    },
  };
};
