/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */

/* eslint-disable @typescript-eslint/no-floating-promises */
import { ThemeProvider } from '@audi/audi-ui-react';
import { useContent, useInViewEffect } from '@oneaudi/feature-app-utils';
import * as React from 'react';
import { ImageFadeOverOnScroll } from './components';
import { Context, useLogger } from './context';
import { useTrackingManager } from './context/useTrackingManager';
import { Content } from './EditorContentTypes';
import { AsyncFeatureAppProps, ImageFadeOverOnScrollProps } from './FeatureAppTypes';
import { createInitialState } from './utils/stateUtils';
import pkg from '../../package.json';

const ImageFadeOverFeatureApp: React.FunctionComponent<ImageFadeOverOnScrollProps> = (
  teaserProps: ImageFadeOverOnScrollProps,
) => {
  const [endOfModuleTracked, setEndOfModuleTracked] = React.useState(false);
  const trackingManager = useTrackingManager();

  React.useEffect(() => {
    trackingManager.ready(pkg.version);
  }, []);

  const impressionTracking = React.useCallback(
    (disableAnimationVariant: boolean): void => {
      useInViewEffect(() => {
        const value = disableAnimationVariant ? 'fallback' : 'standard';
        trackingManager.impression(value);
      });
    },
    [teaserProps],
  );
  const endOfModuleTracking = React.useCallback((): void => {
    if (endOfModuleTracked === false) {
      setEndOfModuleTracked(true);
      trackingManager.endOfModule();
    }
  }, [endOfModuleTracked]);
  return (
    <ThemeProvider>
      <ImageFadeOverOnScroll
        {...teaserProps}
        impressionTracking={impressionTracking}
        endOfModuleTracking={endOfModuleTracking}
      />
    </ThemeProvider>
  );
};

const AsyncFeatureApp: React.FC<AsyncFeatureAppProps> = ({
  asyncStateHolder,
}: AsyncFeatureAppProps) => {
  const [state, setState] = React.useState<ImageFadeOverOnScrollProps | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );
  const { vueFormatterService, localeService } = React.useContext(Context);

  const content = useContent<Content>();
  const logger = useLogger();

  React.useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(content, vueFormatterService, localeService, logger).then(
        (initialState) => {
          if (mounted) setState(initialState);
        },
      );
    }

    return () => {
      mounted = false;
    };
  }, [content]);
  React.useEffect(() => {
    // when asyncStateHolder is a function it means the state could not be properly serialized by the server
    // and it is not available on the client.
    // In that case this effect will try to fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then(setState);
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }

  return <ImageFadeOverFeatureApp {...state} />;
};

export default AsyncFeatureApp;
